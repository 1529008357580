// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import BlockGracias from "../components/afterContact"
export default function Gracias() {
  return (
    <>
      <Layout>
        <BlockGracias text="ERROR"></BlockGracias>
      </Layout>
    </>
  )
}
